import axios from "axios";
const url = process.env.REACT_APP_API_URL;
const websiteId = process.env.REACT_APP_WEBSITE_ID;
const fetchAdditionalData = async (info, props, id) => {
  try {
    let result = {};

    result.Data = await getData(props.Table, props.AllData, id);
    console.log('allllllllllll', props.AllData);
    result.Info = JSON.parse(info);
    let isEnglish = localStorage.getItem('i18nextLng') == "en";
    if(result.Info) result.Info = result.Info.reduce((acc, info) => {
      acc[info.Key] = isEnglish ? info.Value : info.ValueAr;
      return acc;
    }, {});

    if (props.Links) {
      result.Links = props.Links.map(link => ({
        ...link,
        Name: isEnglish ? link.Name : link.NameAr ?? "-"
      }));
    }
    
    return result;
  } catch (error) {
    console.error("Error fetching additional data:", error);
    return null;
  }
};

async function getInfo() {
  const response = await axios.get(
    `${url}/api/GetSiteInfo?websiteId=${websiteId}`
  );

  const reshapedObject = response.data.reduce((acc, item) => {
    acc[item.key] = item.value;
    return acc;
  }, {});

  return reshapedObject;
}

async function getData(table, type, id) {
  const response = await axios.get(
    `${url}/api/LoadTableData?websiteId=${websiteId}&table=${table}&type=${type}&id=${id}`
  );

  if(response.data && response.data.length > 0){
    const updatedDataList = response?.data?.map((item) => {
      if (localStorage.getItem('i18nextLng') == "ar") {
        item.TitleEn = item.TitleAr;
        item.DescriptionEn = item.DescriptionAr;
      }
      return item;
    });

    return updatedDataList;
  }

  return response.data;
}

export default fetchAdditionalData;
