import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Footer = (props) => {
  const { t } = useTranslation(); // Initialize translation hook

  let links = props[0].Links;

  let childs = links.reduce((acc, link) => {
    if (link.ParentId) {
      if (!acc[link.ParentId]) {
        acc[link.ParentId] = [];
      }
      acc[link.ParentId].push(link);
    }
    return acc;
  }, {});
  let { SmallLogo } = props[0].Info;
  childs = Object.values(childs);
  return (
    <>
      <footer className="footer mt-50 rtl">
        <div className="container">
          <div className="footer-top">
            <div className="row">
              {/* <div className="col-md-4 col-sm-6 text-center text-md-start">
                <Link href="/" legacyBehavior>
                  <a>
                    <img
                      src={`${process.env.REACT_APP_API_URL}/${SmallLogo}`}
                      alt="Al Noblaa"
                      // width={100}
                      height={130}
                    />
                  </a>
                </Link>
              </div> */}
              {/* <div className="col-md-8 col-sm-6 text-center text-md-end">
                <span className="color-gray-900 text-heading-6 mr-30 text-mb-sm-20">
                  {t("readyToGetStarted")}
                </span>
                <Link href="/page-signup" legacyBehavior>
                  <a className="btn btn-square">{t("createAccount")}</a>
                </Link>
              </div> */}
            </div>
          </div>
          <div className="row">
            <div className="col-lg-2 width-20 mb-30">
              <Link href="/" legacyBehavior>
                <a>
                  <img
                    src={`${process.env.REACT_APP_API_URL}/${SmallLogo}`}
                    alt="Al Noblaa"
                    // width={100}
                    height={130}
                  />
                </a>
              </Link>
            </div>
            {links
              .filter((link) => link.ParentId === null)
              .map((link, i) => (
                <div className="col-lg-2 width-20 mb-30" key={i}>
                  <h4 className="text-heading-5">{link.Name}</h4>
                  <ul className="menu-footer mt-20">
                    {childs[i].map((item, j) => {
                      return (
                        <li key={j}>
                          <a href={item.Url} legacyBehavior>
                            <a>{item.Name}</a>
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              ))}
          </div>
          <div className="footer-bottom mt-20">
            <div className="row">
              <div className="col-md-6">
                <span className="color-gray-400 text-body-lead">
                  {t("copyright")}
                </span>
                <Link href="/page-terms" legacyBehavior>
                  <a className="text-body-text color-gray-400 ml-50">
                    {t("privacyPolicy")}
                  </a>
                </Link>
                
                <Link href="/page-terms" legacyBehavior>
                  <a className="text-body-text color-gray-400 ml-50">
                    {t("termsOfService")}
                  </a>
                </Link>
              </div>
              <div className="col-md-6 text-center text-lg-end text-md-end">
                <div className="footer-social">
                  <Link href="https://facebook.com" legacyBehavior>
                    <a className="icon-socials icon-facebook"></a>
                  </Link>
                  <Link href="https://twitter.com" legacyBehavior>
                    <a className="icon-socials icon-twitter"></a>
                  </Link>
                  <Link href="https://www.instagram.com" legacyBehavior>
                    <a className="icon-socials icon-instagram"></a>
                  </Link>
                  <Link href="https://www.linkedin.com" legacyBehavior>
                    <a className="icon-socials icon-linkedin"></a>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
